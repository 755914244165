
import React from 'react';
import ImageCarousel from '../../components/GalleryCarasoul/ImageCarousel.component';


const Gallery = () => {

    return (
        <div className="container" style={{ marginTop: '2rem' ,padding:24, minHeight: "80vh"}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
               <ImageCarousel/>
            </div>
        </div>
    );
}

export default Gallery;
