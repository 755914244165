import React from "react";
import backGroundImage from '../../../assets/Images/macbook.png';
import servicesData from "../../../Mockdata/Services";
import Carasoul2 from "../../../components/Carasoule2/Carasoul2.component";

const Services = (): React.JSX.Element => {
  return (
    <div>
        <div style={{ display: 'flex', flexDirection: 'column',marginTop:'2vh',alignItems:"center",justifyContent:"center" }}>
          {servicesData.map((data, index) => (
            <Carasoul2 key={index} data={data} index={index} />
          ))}
      </div>
    </div>
  );
}

export default Services;
