// src/Carousel.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import sharecarpool from '../../assets/Images/sharecarpool.jpeg'
import shareCars from '../../assets/Images/share.jpeg';
import './Carasoul.css';
import { MockData } from '../Cards/Cards.models';
import mobile from '../../assets/Images/mobile 1.png'
import Scanner from '../../assets/Images/Scanner.jpeg'
import { EColor } from '../../styles/Colors/Colors';
import Banner from '../../assets/Images/Banner.jpg'
import { auto } from '@popperjs/core';


interface CarouselProps {
  data: MockData;
  index: number;
  isDownload?: boolean;
}

function Carousel({ data, index, isDownload }: CarouselProps) {
  return (
    <div className="container-fluid">
      {index === 0 && !isDownload && (
        <div className="row">
          {/* Left side: Description */}
          <div className="col-md-6 d-flex align-items-center" data-aos="zoom-in">
            <div className="carousel-description p-4 border rounded">
              <h3 className="carsoulBoxHeading">{data.title}</h3>
              <p className="carsoulBoxContent">
                {data.content}
              </p>
            </div>
          </div>
          {/* Right side: Image carousel */}
          <div className="col-md-6" data-aos="zoom-in">
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                {/* Add more buttons for additional slides if needed */}
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={sharecarpool} className="d-block w-100" alt="Carousel 1" />
                </div>
                {/* <div className="carousel-item">
                  <img src={shareCars} className="d-block w-100" style={{ borderRadius: '15px' }} alt="Carousel 2" />
                </div> */}
                {/* Add more carousel items for additional images */}
              </div>
              {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button> */}
            </div>
          </div>
        </div>
      )}

      {index === 1 && !isDownload && (
        <div className="row" id='row2' >
           <div className="col-md-6" data-aos="zoom-in">
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                {/* Add more buttons for additional slides if needed */}
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={shareCars} className="d-block w-100" alt="Carousel 1" />
                </div>
                {/* <div className="carousel-item">
                  <img src={shareCars} className="d-block w-100" style={{ borderRadius: '15px' }} alt="Carousel 2" />
                </div> */}
                {/* Add more carousel items for additional images */}
              </div>
              {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button> */}
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center" data-aos="zoom-in">
            <div className="carousel-description p-4 border rounded" >
              <h3 className="carsoulBoxHeading">{data.title}</h3>
              <p className="carsoulBoxContent">
                {data.content}
              </p>
            </div>
          </div>
        </div>
      )}

      {isDownload && index === 0 && (
       <div className="container-fluid">
       <div className='row'>
         <div className="col-md-6 d-flex align-items-center" data-aos="zoom-in">
           <div className="carousel-description p-4 border rounded">
             <h3 className="carsoulBoxHeading">{data.title}</h3>
             <p className="carsoulBoxContent">
               {data.content}
             </p>
             <a href="https://play.google.com/store/apps/details?id=com.travobuddy" className="btn-custom btn-primary mt-4" target="_blank" rel="noopener noreferrer">Download from Play Store</a>
           </div>
         </div>
         <div className="col-md-6" data-aos="zoom-in">
           <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
             <div className="carousel-indicators">
               <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
               <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
               {/* Add more buttons for additional slides if needed */}
             </div>
             <div className="carousel-inner">
               <div className="carousel-item active">
                 <img src={Scanner} className="d-block w-50 mt-4" alt="Carousel 1" />
               </div>
               {/* <div className="carousel-item">
                 <img src={mobile} className="d-block w-70 mt-4" style={{ borderRadius: '15px', height: 100 }} alt="Carousel 2" />
               </div> */}
               {/* Add more carousel items for additional images */}
             </div>
             {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
               <span className="carousel-control-prev-icon" aria-hidden="true"></span>
               <span className="visually-hidden">Previous</span>
             </button>
             <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
               <span className="carousel-control-next-icon" aria-hidden="true"></span>
               <span className="visually-hidden">Next</span>
             </button> */}
           </div>
         </div>
       </div>
     </div>
 
      )}

{index === 2 && !isDownload && (
        <div className="row">
          <div className="col-md-6 d-flex align-items-center" data-aos="zoom-in">
            <div className="carousel-description p-4 border rounded" >
              <h3 className="carsoulBoxHeading">{data.title}</h3>
              <p className="carsoulBoxContent" >
                {data.content}
              </p>
            </div>
          </div>
          <div className="col-md-6" data-aos="zoom-in">
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                {/* Add more buttons for additional slides if needed */}
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={Banner} className="d-block w-100" alt="Carousel 1" />
                </div>
                {/* <div className="carousel-item">
                  <img src={shareCars} className="d-block w-100" style={{ borderRadius: '15px' }} alt="Carousel 2" />
                </div> */}
                {/* Add more carousel items for additional images */}
              </div>
              {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Carousel;