import React from 'react';
import  './Cards.css'
import { EColor } from '../../styles/Colors/Colors';

interface ICardProps {
    title: string; // Explicitly define title as a string
    content: string;
    imageUrl?: string; // Optional imageUrl prop
  }

const Cards = ({ title, content, imageUrl }: ICardProps): React.JSX.Element => {
  return (
    <div className="card custom-shadow" data-aos="zoom-in">
    <div className="card-body">
      <h5 className="card-title" style={{color:EColor.DARKBLUE}}>{title}</h5>
      <p className="card-text" style={{ color: EColor.LIGHTGREEN }}>{content}</p>
    </div>
  </div>
  );
}

export default Cards;
