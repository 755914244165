import { MockData } from "../components/Cards/Cards.models";

const mockData: MockData[] = [
    {
      title: 'Your Pick of rides at low prices',
      content: `Experience the best rides at unbeatable prices with Travobuddy. Whether you're commuting to work or planning a weekend getaway, choose from a variety of ride options tailored to fit your budget and preferences. Enjoy the convenience, safety, and affordability that Travobuddy offers for all your travel needs. Download now from the Play Store and start your journey with us!`
    },
    {
      title: 'Save Petrol, Go Green',
      content: 'Optimize your travel with Travobuddy by sharing rides and minimizing fuel consumption. Join our eco-conscious community and enjoy affordable journeys while protecting the environment. Share rides, save petrol, and go green with every mile. Download now from the Play Store and start your sustainable journey today!'
    },
    {
      title: 'Travel with Safety',
      content: `Travel confidently with Travobuddy, where your safety is our priority. Our platform connects you with verified drivers and well-maintained vehicles, ensuring a secure journey every time. Whether you're commuting daily or exploring new destinations, enjoy peace of mind with our reliable and safe travel options. Download now from the Play Store and start your safe journey with us!`
    },
    // Add more mock data objects as needed
  ];

  export default mockData