import { IconType } from 'react-icons'; // Import IconType from React Icons
import { AiOutlineAim } from "react-icons/ai";
import { FaHeart } from "react-icons/fa";
import { TbArrowRoundaboutLeft } from "react-icons/tb";



interface AboutUsItem {
  title: string;
  icon: IconType;
  aos: string; 
  description: string;
}

const aboutUsData: AboutUsItem[] = [
  {
    title: "About TravoBuddy",
    aos:"zoom-in",
    icon: TbArrowRoundaboutLeft,
    description: "TravoBuddy is your go-to app for affordable, eco-friendly carpooling. Launched MVP on February 15, 2024, our mission is to connect drivers with empty seats to passengers looking for rides. We promote sustainability, safety, community, affordability, and innovation in travel. Join us and be part of a responsible travel community.",
  },
  {
    title: "Our Mission",
    icon: AiOutlineAim,
    aos:"zoom-in",
    description: "Our mission is to provide a safe, convenient, and economical carpooling solution that reduces carbon footprint and fosters community-driven travel experiences. Join us in our journey towards sustainable and affordable mobility solutions.",
  },
  {
    title: "Why Choose TravoBuddy?",
    icon: FaHeart,
    aos:"zoom-in",
    description: "TravoBuddy offers a seamless platform for drivers and passengers to connect, ensuring cost-effective travel while contributing to environmental sustainability. With user-friendly features and a commitment to safety, we strive to enhance your travel experience.",
  },
];


export default  aboutUsData
  