import React, { useEffect } from 'react'
import "./AdminPanelStyle.css"
import AdminPanelCards from './AdminPanelCards'
import { Link, useNavigate } from 'react-router-dom'

const AdminPanel = () => {
  const navigate = useNavigate()
  useEffect(()=>{
    const userId = localStorage.getItem("userKey")
    if(userId!=="asWqFreafdgADFGRE@#%34563"){
      navigate("/LogIn")
    }
  },[])
  const LogOut = () => {
    localStorage.removeItem("userKey")
    navigate("/LogIn")
  }
  return (
    <div className='adminPanel'>
      <div className='adminPanelHeading' >
        <div className='logOutBalanceButton'></div>
        <h2>Admin Panel</h2>
        <div className='logOutButton' onClick={()=>LogOut()}>Log Out</div>
      </div>
      <div className='cardContainer' >
        {AdminPanelCards.map((cards) => 
          <Link to={`./${cards.title}Table`}
            style={{textDecoration: "none"}}>
            <div className='cards'>
                <div className='cardTitle'>
                    {cards.title}
                </div>
                <div className='cardContent'>
                    {cards.content}
                </div>
                <div className='cardSubContent'>
                    {cards.subContent}
                </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  )
}

export default AdminPanel
