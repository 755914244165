import React from 'react';
import './App.css';
import { Route, Routes } from "react-router-dom";
import DashBoard from './Application/screens/Dashboard/DashBoard.component';
import Layout from './components/Layout/Layout.component';
import Navbar from './components/Navbar/Navbar.components';
import Footer from './components/Footer/Footer.component';
import Services from './Application/screens/Services/Services.components';
import AboutUs from './Application/Aboutus/AboutUs.component';
import ContactUs from './Application/ContactUs/ContactUs.component';
import Gallery from './Application/Gallery/Gallery.components';
import LogIn from './Application/LogIn/LogIn';
import AdminPanel from './Application/AdminPanel/AdminPanel';
import VendorTable from './Application/AdminPanel/Vendor/VendorTable';
import CustomerTable from './Application/AdminPanel/customer/CustomerTable';
import TermsAndCondition from './Application/TermsAndPrivacy/TermsAndCondition/TermsAndCondition';
import PrivacyPolicy from './Application/TermsAndPrivacy/PrivacyPolicy/PrivacyPolicy';

function App() {
  return (
    <div className="background-image">
     <Navbar/>
     <div className="main-content">
        <Routes>
          <Route  path='/' element={<DashBoard/>}/>
          <Route  path='/Services' element={<Services/>}/>
          <Route  path='/AboutUs' element={<AboutUs/>}/>
          <Route  path='/ContactUs' element={<ContactUs/>}/>
          <Route  path='/Gallery' element={<Gallery/>}/>
          <Route  path='/LogIn' element={<LogIn/>}/>
          <Route  path='/AdminPanel' element={<AdminPanel/>}/>
          <Route  path='/AdminPanel/VendorTable' element={<VendorTable/>}/>
          <Route  path='/AdminPanel/CustomerTable' element={<CustomerTable/>}/>
          <Route  path='/TermsConditions' element={<TermsAndCondition/>}/>
          <Route  path='/PrivacyPolicy' element={<PrivacyPolicy/>}/>
          <Route  path='/v1/TravohealthMetric' element={<DashBoard/>}/>
        </Routes>
      </div>
     <Footer/>
    </div>
  );
}

export default App;
