import React, { useEffect, useState } from 'react'
import "./CustomerTableStyle.css"
import { Link, useNavigate } from 'react-router-dom';
import loader from "../../../assets/Images/loader-icon.webp"
import arrowBack from "../../../assets/Images/arrow_back.png"
import { useAppDispatch, useAppSelector } from '../../../states/store';
import { getRefundCustomerData } from '../../../states/slice/RefundCustomerData/RefundCustomerSlice';
import { CustomerData } from '../../../states/slice/CustomerData/CustomerModels';

const CustomerTable = () => {

  const navigate = useNavigate()
  useEffect(()=>{
    const userId = localStorage.getItem("userKey")
    if(userId!=="asWqFreafdgADFGRE@#%34563"){
      navigate("/LogIn")
    }
  },[])

  const [CustomerDisplayOption, setCustomerDisplayOption] = useState(false);
  const [allCustomer, setallCustomer] = useState(false);
  const [ShowDetailsStatus , setShowDetailsStatus] = useState(Number)
  const [CustomerDataDisplayOpetion, getCustomerDataDisplayOpetion] = useState(true)
  const [CustomerDataList, getCustomerDataList] = useState<CustomerData | undefined>()
  const [CustomerDataListStatus, getCustomerDataListStatus] = useState<string | null>()
  const dispatch = useAppDispatch();
  const { RefundCustomerData, RefundStatus } = useAppSelector((state) => state.RefundCustomerReducer)

  console.log(RefundCustomerData)
  console.log(RefundStatus)


  useEffect(()=>{
    let CustomerDisplay: string = CustomerDisplayOption? "A" : "I";
    dispatch(getRefundCustomerData(CustomerDisplay)) 
  },[CustomerDisplayOption])


  return (
    <div className='customerTable'>
      <div className='customerTableTitle'>
        <Link to="/AdminPanel" className='backArrow'>
          <img className='arrow' src={arrowBack} />
        </Link>
        <h2>Customer List</h2>
      </div>
      <div className='customerCountBox'>
        <div className='customerCountSubBox'>
          <div className='customerCountCardsTotal' onClick={()=> getCustomerDataDisplayOpetion(true)}>
            <div className='customerType'>Total Customers</div>
            <div className='customerCount'>101</div>
          </div>
        </div>
        <div className='customerCountSubBox'id='CustomerRefund'>
          <div className={!CustomerDisplayOption?'customerCountCardsActive':'customerCountCards'} onClick={()=> setCustomerDisplayOption(false)}>
            <div className='customerType'>Pending Refund</div>
            <div className='customerCount'>{!CustomerDisplayOption?  RefundCustomerData?.data.length: 0}</div>
          </div>
          <div className={CustomerDisplayOption?'customerCountCardsActive':'customerCountCards'} onClick={()=> setCustomerDisplayOption(true)}>
            <div className='customerType'>Refund</div>
            <div className='customerCount'>{CustomerDisplayOption?  RefundCustomerData?.data.length: 0}</div>
          </div>
        </div>
      </div>
      <div className='customerDataTable'>
        <div className='customerDataTableHeadingBlock'>
          <div className='customerDataTableHeadingSrNo'>SrNo</div>
          <div className='customerDataTableHeadingName'>Name</div>
          <div className='customerDataTableHeadingDate'>Date</div>
          <div className='customerDataTableHeadingMobileNo'>Mobile</div>
          <div className='customerDataTableHeadingRideDetails'>Ride Details</div>
          <div className='customerDataTableHeadingPrice'>Price</div>
          <div className='customerDataTableHeadingDone'>View</div>
        </div>
        <div className='customerDataTableContentBlock'>
          {
            RefundStatus === "loading" ? 
            <div style={{width: "100%", height: "100px",display: "flex", justifyContent: "center", alignItems: "center"}}>
              <img style={{height: "40px"}} src={loader} alt="Loading" />
            </div> : null
          }
          {RefundStatus=== "idel"? RefundCustomerData?.data.map((item: any, index: number)=> 
            {
              return(
                <div>
                  <div style={{display: "flex" }}>
                    <div className='customerDataTableSrNo'>{index+1}</div>
                    <div className='customerDataTableName'>{item.customerFirstName} {item.customerLastName}</div>
                    <div className='customerDataTableDate'>{item.bookingDate.split("T")[0]}</div>
                    <div className='customerDataTableMobileNo'>{item.customerMobileNum}</div>
                    <div className='customerDataTableRideDetails'>{item.customerSource.split(",")[0]} To {item.customerDestination.split(",")[0]}</div>
                    <div className='customerDataTablePrice'>{item.price} Rs.</div>
                    <div className='customerDataTableDone'>
                      <button 
                      onClick={()=> setShowDetailsStatus(item.bookingId)} 
                      className='customerDataTableDoneButton'>View</button>
                    </div>
                  </div>
                  <div>
                    {ShowDetailsStatus ===  item.bookingId? 
                    // {true ?
                      <div  className='customerDetailsMain'>
                        <div className='customerDetails'>
                          <div className='customerDetailsCardOne'>
                            <p>Date : {item.bookingDate.split("T")[0]} || Time : {item.bookingDate.split("T")[1]} </p>
                            <p>Ride : {item.customerSource} To {item.customerDestination}</p>
                            <p>AvailableSeat : {item.vendorAvailableSeat} </p>
                            <p>Vendor Name : {item.vendorFirstName} {item.vendorLastName}</p>
                            <p>Vendor Location : {item.vendorCurrentLocation} </p>
                            <p>Vendor Phone No : {item.vendorMobileNum}</p>
                            <p>Vehicle Number : {item.vendorVehicleName} </p>
                          </div>
                          <div className='customerDetailsCardTwo'>
                            <p>Booking Id : {item.bookingId}</p>
                            <p>Transaction Id : {item.transactionId}</p>
                            <p>Coupon disscount : 0 Rs.</p>
                            <p>Ride Ammount : {item.price} Rs.</p>
                            <p>Booked Seats : {item.customerBookSeat} </p>
                            <p>Suggestion : {item.price} - 0 = {item.price} Rs.</p>
                            {CustomerDisplayOption? 
                              <div>
                                <p style={{color: "green"}}>Refund Completed </p>
                              </div>
                              :
                              <div>
                                <input type="text" placeholder='Enter Refund ammount' />
                                <button className='customerDataTableRefundButton'>Submit</button>
                              </div>
                            }
                          </div>
                        </div>
                        <div style={{height: "20px",cursor: "pointer", fontWeight: "600", paddingLeft: "8px", fontSize: "20px"}} onClick={()=>setShowDetailsStatus(0)}>X</div>
                      </div>
                      : null
                    }
                  </div>
                </div>
              );
            }
          ): null}
          {
            RefundStatus === "error" ? 
            <div>Error...</div> : null
          }
        </div>
      </div>
    </div>
  )
}

export default CustomerTable;

