import React, { useEffect, useState } from 'react'
import "./VendorTableStyle.css"
import { Link, useNavigate } from 'react-router-dom';
import arrowBack from "../../../assets/Images/arrow_back.png"
import loader from "../../../assets/Images/loader-icon.webp"
import { useAppDispatch, useAppSelector } from '../../../states/store';
import { getVerifiedVendorData } from '../../../states/slice/VerifiedVendorData/VerifiedVendorSlice';
import { ManageValidationStatus } from '../../../states/slice/VendorValidationStatus/ManageValidation';
import { GetVendorData, GetVendorDataResponse } from '../../../states/slice/VerifiedVendorData/VerifiedVendorModels';
import { getUnverifiedVendorData } from '../../../states/slice/UnverifiedVendorData/UnverifiedVendorSlice';


const VendorTable = () => {
  const navigate = useNavigate()
  
  useEffect(()=>{
    const userId = localStorage.getItem("userKey")
    if(userId!=="asWqFreafdgADFGRE@#%34563"){
      navigate("/LogIn")
    }
  },[])

  const [VendorsDisplayOption, setVendorsDisplayOption] = useState(false);
  const [checkBox, setCheckBox] = useState(Boolean);
  const [custId, getCustId] = useState(Number)
  const [AadharCard, getAadharCard] = useState(Number)
  const [VenderDataList, getVenderDataList] = useState<GetVendorData | undefined>()
  const [VenderDataListStatus, getVenderDataListStatus] = useState<string | null>()

  const dispatch = useAppDispatch();
  const {verifiedVendorData , verifiedStatus} = useAppSelector((state) => state.verifiedVendorsReducer)
  const {UnverifiedVendorData , UnverifiedStatus} = useAppSelector((state) => state.unverifiedVendorReducer)
  const {updateVendorData, updateStatus} = useAppSelector((state) => state.ManageValidationReducer)

  useEffect(()=>{
    if (VendorsDisplayOption === true){
    getVenderDataList(verifiedVendorData)
    getVenderDataListStatus(verifiedStatus)
    }
    if (VendorsDisplayOption === false){
    getVenderDataList(UnverifiedVendorData)
    getVenderDataListStatus(UnverifiedStatus)
    }
  },)
  
  useEffect(()=>{
    dispatch(getVerifiedVendorData(true)) 
    dispatch(getUnverifiedVendorData(false)) 

    setCheckBox(VendorsDisplayOption)
  },[VendorsDisplayOption])

  useEffect(()=>{
    if(updateVendorData === "idel") {
      dispatch(getVerifiedVendorData(true)) 
      dispatch(getUnverifiedVendorData(false)) 
    }
  },[updateVendorData])

  const setVendorVerifiedStatus = ()=>{
    const setValidationStatus ={
      "vendorVerified": checkBox,
      "vendorId": custId
    }
    dispatch(ManageValidationStatus(setValidationStatus))
  }

  return (
    <div className='vendorTable'>
      <div className='vendorTableTitle'>
        <Link to="/AdminPanel" className='backArrow'>
          <img className='arrow' src={arrowBack} />
        </Link>
        <h2>Vendor List</h2>
      </div>
      <div className='vendorCountBox'>
        {/* <div className='vendorCountSubBox'>
          <div className='vendorCountCardsTotal' >
            <div className='vendorType'>Total Vendors</div>
            <div className='vendorCount'>101</div>
          </div>
        </div> */}
        <div className='vendorCountSubBox'id='verifiedVendors'>
        <div className={!VendorsDisplayOption?'vendorCountCardsActive':'vendorCountCards'} onClick={()=>setVendorsDisplayOption(false)}>
            <div className='vendorType'>Unverified</div>
            <div className='vendorCount'>{UnverifiedVendorData?.data.length}</div>
          </div>
          <div className={VendorsDisplayOption?'vendorCountCardsActive':'vendorCountCards'} onClick={()=> setVendorsDisplayOption(true)}>
            <div className='vendorType'>Verified</div>
            <div className='vendorCount'>{verifiedVendorData?.data.length}</div>
          </div>
        </div>
      </div>
      <div className='vendorDataTable'>
        <div className='vendorDataTableHeadingBlock'>
          <div className='vendorDataTableHeadingSrNo'>SrNo</div>
          <div className='vendorDataTableHeadingName'>Name</div>
          <div className='vendorDataTableHeadingAadharCard'>Aadhar</div>
          <div className='vendorDataTableHeadingMobileNo'>Mobile</div>
          <div className='vendorDataTableHeadingCustID'>Id</div>
          <div className='vendorDataTableHeadingVerified'>Verify</div>
          <div className='vendorDataTableHeadingDone'>Done</div>
        </div>
        <div className='vendorDataTableContentBlock'>
          {
            VenderDataListStatus === "loading" ? 
            <div style={{width: "100%", height: "100px",display: "flex", justifyContent: "center", alignItems: "center"}}>
              <img style={{height: "40px"}} src={loader} alt="Loading" />
            </div> : null
          }
          {VenderDataListStatus === "idel" ? VenderDataList?.data.map((item:any, index:number)=> 
            { 
              return(
                <div>
                  <div style={{display: "flex"}}>
                    <div className='vendorDataTableSrNo'>{index+1}</div>
                    <div className='vendorDataTableName'>{item.firstName} {item.lastName}</div>
                    <div className='vendorDataTableAadharCard'><button className='vendorDataTableDoneButton' onClick={()=> getAadharCard(item.vendorId)}>View</button></div>
                    <div className='vendorDataTableMobileNo'>{item.mobileNum}</div>
                    <div className='vendorDataTableCustID' onClick={()=>navigator.clipboard.writeText(item.vendorId)}>{item.vendorId}</div>
                    <div className='vendorDataTableVerified'><input onChange={(event)=> {{setCheckBox(event.target.checked)}{getCustId(item.vendorId)}}} type='checkbox' className='vendorDataTableIsVerifiedCheckBox' defaultChecked={item.vendorVerified} ></input></div>
                    <div className='vendorDataTableDone'>
                      <button 
                      disabled={updateStatus}
                      onClick={()=> custId === item.vendorId? setVendorVerifiedStatus() : null}
                      className='vendorDataTableDoneButton'>Done</button>
                    </div>
                  </div>
                  <div>
                    {AadharCard ===  item.vendorId? 
                    // {true ?
                      <div style={{width: "auto",display: "flex", justifyContent: 'center', flexDirection: "row", backgroundColor: "lightgray", margin: "4px 0px"}}>
                        <Link to={item.aadharCardUrl} target='blank'>
                          <img style={{height: "200px", margin: "10px 0px"}} src={item.aadharCardUrl} alt="Aadhar Card" />
                        </Link>
                        <p style={{height: "20px",cursor: "pointer", fontWeight: "600", paddingLeft: "8px", fontSize: "20px"}} onClick={()=>getAadharCard(0)}>x</p>
                      </div>
                      : null
                    }
                  </div>
                </div>
              );
            }
          ): null}
          {
            VenderDataListStatus === "error" ? 
            <div>Error...</div> : null
          }
        </div>
      </div>
    </div>
  )
}

export default VendorTable;



