import React from 'react';
import { IconContext } from 'react-icons';
import { AboutUsItem } from './AboutUCard.models';

const AboutUsCard = ({title,description,icon,aos}:AboutUsItem) => {
  return (
    <div className="card mt-4" style={{ maxWidth: '20rem', backdropFilter: 'blur(10px)',backgroundColor: 'rgba(255, 255, 255, 0.5)' }} data-aos={aos}>
    <div className="card-body">
      <div className="d-flex align-items-center mb-3">
        <IconContext.Provider value={{ size: '3rem', style: { marginRight: '1rem' } }}>
          {React.createElement(icon)}
        </IconContext.Provider>
        <h5 className="card-title mb-0">{title}</h5>
      </div>
      <p className="card-text">
        {description}
      </p>
    </div>
  </div>
  );
};


export default AboutUsCard;
