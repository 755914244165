import { MockData } from "../components/Cards/Cards.models";

const carasoulData: MockData[] = [
    {
      title: 'Share Your ride',
      content: `Share your ride and share the journey! Join our community of eco-conscious commuters saving time, money, and the planet. Whether you're heading to work or an adventure, make every mile count with friendly faces and shared costs. Embrace convenience, reduce your carbon footprint, and connect with like-minded travelers today!`
    },
    {
      title: 'Find Your ride With Travobuddy',
      content: `Find your ride with TravoBuddy and discover hassle-free travel solutions! Whether you're commuting daily or exploring new destinations, our platform connects you with reliable drivers heading your way. Enjoy convenience, cost-sharing benefits, and eco-friendly travel options. Join now to simplify your journey and connect with a community dedicated to smarter, greener transportation.`
    },
    {
      title: 'Discover Travobuddy: Your Gateway to Easy and Rewarding Rides!"',
      content: `Travobuddy: Connecting rides for seamless journeys! Join now and get a jump-start with a ₹50 travel bonus. Whether you're commuting or planning a trip, Travobuddy makes sharing rides easy and rewarding. Start saving and sharing today!`
    },
  ];

  export default carasoulData