import React from "react"; 
import AoutUs from '../../assets/Images/aboutUS.jpg'
import { EColor } from "../../styles/Colors/Colors";
import AboutUsCard from "../../components/AboutUsCard/AboutUsCard.component";
import aboutUsData from "../../Mockdata/AboutUs";
import './AboutUs.css'

const AboutUs = (): React.JSX.Element => {
  return (
    <div className="main-container" >
    <div className="containers">
      <div className="Text" data-aos="zoom-in" >
        <h2 style={{color:EColor.DARKBLUE, textAlign:"center"}}>Safe and Cheap Way to Commute Together</h2>
        <p style={{color:EColor.DARKBLUE}}>Your go-to app for affordable, eco-friendly carpooling. Launched MVP on 15-Feb-2024, we connect drivers with empty seats to passengers looking for rides. Our mission is to promote sustainability, safety, community, affordability, and innovation in travel. Join us and be part of a responsible travel community. Download the app today!</p>
      </div>
      <div data-aos="zoom-in">
        <img src={AoutUs} className="img" alt="About Us" />
      </div>
    </div>
    <div className="goal">
    {aboutUsData.map((res, index) => (
            <AboutUsCard title={res.title} icon={res.icon} description={res.description}  aos={res.aos}/>
        ))}
    </div>
  </div>
  );
}

export default AboutUs;
