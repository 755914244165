import React from "react";
import Contactus from "../../assets/Images/contactus1.jpg";
import { EColor } from "../../styles/Colors/Colors"; 
import './ContactUs.css'

const ContactPage = (): React.ReactElement => {

      return (
        <div className="container" style={{ marginTop: '2vw' }}>
          <div className="main">
            <div className="col-lg-6 order-lg-2" data-aos="zoom-in" style={{margin: "10px"}}>
              <img src={Contactus} className="img-fluid image"  style={{ borderRadius: '20px' }} alt="Contact Us" />
            </div>
            <div className="col-lg-4 order-lg-1" data-aos="zoom-in" style={{ backdropFilter: 'blur(10px)', padding: '20px', backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '20px', margin: '20px' }}>
              <h2 style={{ color: EColor.DARKBLUE }}>Simplify Your Commute with TravoBuddy</h2>
              <p  className="fonts" style={{ color: EColor.DARKBLUE ,}}>
                TravoBuddy is your eco-friendly solution for affordable carpooling. Connect with drivers or passengers seamlessly to save costs and reduce your carbon footprint. Join us in promoting sustainable travel today!
              </p>
              <div style={{ marginTop: '2rem', color: EColor.DARKBLUE }}>
                <h3>Contact Information</h3>
                <ul className="fonts" style={{ listStyleType: 'none', padding: 0  }}>
                  <li><strong>Address:</strong> A1,502, Royal Oak ,Wakad ,Pune ,MH,India.</li>
                  <li><strong>Phone:</strong> +91-8879410012</li>
                  <li><strong>Email:</strong> admin@vipracube.com</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    };


export default ContactPage;
