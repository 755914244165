import React, { useEffect, useState } from 'react'
import "./LogInStyle.css"
import { useNavigate } from 'react-router-dom'
import loginData from './LogInAccess/LogInAccess'
import { useAppSelector } from '../../states/store'


const LogIn = () => {
  const navigate = useNavigate();
    const [alert, setAlert] = useState(false)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [emptyError, setEmptyError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const LogInValidation = ()=> {
      if(username===loginData.userName && password===loginData.password){
        localStorage.setItem("userKey", "asWqFreafdgADFGRE@#%34563")
        navigate("/AdminPanel")
      }
      if(username.length===0 && password.length===0){
        setEmptyError(true)
        setAlert(false)
      }
      else{
        setEmptyError(false)
        setAlert(true)
      }
    }
    
  return (
    <div className='logInMain'>
      <div className='logIn'>
        <form className="logInForm">
          <h2>Log In</h2>
          <div className="logInInput">
            <div className='logInName'>
              <input className='logInInputName' type="text" placeholder="Enter Username"
              onChange={(data)=>setUsername(data.target.value)}
              />
            </div>
            <div className='logInPass'>
              <input className='logInInputPass' type={showPassword? "text" : "password"} placeholder="Enter Password" 
              onChange={(data)=>setPassword(data.target.value)}
              />
              <div onClick={()=>setShowPassword(!showPassword)} className='showPassword'>{showPassword? "Hide" : "Show"}</div>
            </div>
            
            
            {alert? <p className='alertMessage' style={{color: "red"}}>Enter valid Username & Password</p>:<></>}
            {emptyError? <p className='alertMessage' style={{color: "red"}}>Enter Username & Password</p>:<></>}
          </div>
          <div className="logInSubmit">
            <div className='logInButton'
            onClick={()=>LogInValidation()}
            >Log In</div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LogIn
