import React from "react";
import Carousel from "../../../components/Carasoul/Carasoul.componets";
import Cards from "../../../components/Cards/Cards.component";
import Image from '../../../assets/Images/Rectangle 2.png'
import mockData from "../../../Mockdata/MockData";
import MockData from "../../../Mockdata/MockData";
import carasoulData from "../../../Mockdata/CarasoulData";
import DownloadData from "../../../Mockdata/mobileData";
import Videosrc from '../../../assets/Images/2.png'

const DashBoard  = ()=>{
  return(
    <div className="container" style={{marginTop:'2vw'}} >
      
      <div className="carousel slide  mt-4 rounded">
        <div style={{ padding: 16 }}>
          {carasoulData.map((data, index) => (
            <Carousel data={data} index={index} />
          ))}
        </div>
        <div className="row">
          {mockData.map((data, index) => (
            <div className="col-md-4 mt-4" key={index}>
              <Cards title={data.title} content={data.content} />
            </div>
          ))}
        </div>
        <div className="row">
        </div>
        <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
          {DownloadData.map((data, index) => (
              <Carousel data={data} index={index} isDownload={true} />
          ))}
        </div>
      </div>

    </div>
  )
}

export default DashBoard;