import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Splash from '../../assets/Images/Splash.jpg';
import { EColor } from "../../styles/Colors/Colors";
import './navBar.css'

const Navbar = () => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    const handleLinkClick = (path: string) => {
        setActiveLink(path);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light" style={{
            width: '100%',
            zIndex: 1000,
        }}>
            <div className="container-fluid">
                <div className="d-flex align-items-center">
                    <img src={Splash} className="logo"  alt="Splash" />
                    <Link to="/" className="navbar-brand"><p className="travo">TravoBuddy</p></Link>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarTogglerDemo02">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link
                                to="/"
                                className="nav-link"
                                style={{
                                    color: EColor.DARKBLUE,
                                    fontWeight: "normal",
                                    textDecoration: 'none',
                                    backgroundColor: activeLink === "/" ?'white' : "transparent",
                                    padding:15,
                                    borderRadius: activeLink === "/" ? "15px" : "0",
                                }}
                                onClick={() => handleLinkClick("/")}
                            >
                                <strong>Home</strong>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/Services"
                                className="nav-link"
                                style={{
                                    color:  EColor.DARKBLUE,
                                    fontWeight: "normal",
                                    textDecoration: 'none',
                                    padding:15,
                                    backgroundColor: activeLink === "/Services" ? 'white' : "transparent",
                                    borderRadius: activeLink === "/Services" ? "15px" : "0",
                                }}
                                onClick={() => handleLinkClick("/Services")}
                            >
                                <strong>Features</strong>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/Gallery"
                                className="nav-link"
                                style={{
                                    color:  EColor.DARKBLUE,
                                    fontWeight: "normal",
                                    textDecoration: 'none',
                                    padding:15,
                                    backgroundColor: activeLink === "/Gallery" ? 'white' : "transparent",
                                    borderRadius: activeLink === "/Gallery" ? "15px" : "0",
                                }}
                                onClick={() => handleLinkClick("/Gallery")}
                            >
                                <strong>Gallery</strong>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/AboutUs"
                                className="nav-link"
                                style={{
                                    color:  EColor.DARKBLUE,
                                    fontWeight: "normal",
                                    textDecoration: 'none',
                                    padding:15,
                                    backgroundColor: activeLink === "/AboutUs" ?  'white' : "transparent",
                                    borderRadius: activeLink === "/AboutUs" ? "15px" : "0",
                                }}
                                onClick={() => handleLinkClick("/AboutUs")}
                            >
                                <strong>About us</strong>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/ContactUs"
                                className="nav-link"
                                style={{
                                    color:  EColor.DARKBLUE,
                                    fontWeight: "normal",
                                    textDecoration: 'none',
                                    padding:15,
                                    backgroundColor: activeLink === "/ContactUs" ? 'white' : "transparent",
                                    borderRadius: activeLink === "/ContactUs" ? "15px" : "0",
                                }}
                                onClick={() => handleLinkClick("/ContactUs")}
                            >
                                <strong>Contact us</strong>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/LogIn"
                                className="nav-link"
                                style={{
                                    color:  EColor.DARKBLUE,
                                    fontWeight: "normal",
                                    textDecoration: 'none',
                                    padding:15,
                                    backgroundColor: activeLink === "/LogIn" ? 'white' : "transparent",
                                    borderRadius: activeLink === "/LogIn" ? "15px" : "0",
                                }}
                                onClick={() => handleLinkClick("/LogIn")}
                            >
                                <strong>Log In</strong>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;
