import React from 'react'
import "./TermsAndConditionStyle.css"
import { Link } from 'react-router-dom';

const TermsAndCondition = () => {
  return (
    <div className='termsAndConditionMain'>
      <div className='termsAndCondition'>
        <div className='headingContainerTC'>
            <h2 className='headingTC'>Terms And Condition</h2>
        </div>
        <div className='headingContentTC'>
            <p>This App "TravoBuddy" is owned and managed by Vipracube Tech Solutions private limited. By using our car booking services, you agree to the fullowing terms and conditions.</p>
        </div>
        <ol className='mainContentTC'>
            <li className='listMainTC'>Booking:
                <ul>
                    <li className='listContentTC'>You must provide accurate information while booking a car through our app.</li>
                </ul>
            </li>
            <li className='listMainTC'>Payment:
                <ul>
                    <li className='listContentTC'>Payment for the car booking must be made in advance through the app's designated payment methods.</li>
                </ul>
            </li>
            <li className='listMainTC'>Cancellation:
                <ul>
                    <li className='listContentTC'>Cancellation policies apply. Check the app for details on cancellation fees and deadlines.</li>
                </ul>
            </li>
            <li className='listMainTC'>Responsibility:
                <ul>
                    <li className='listContentTC'>Users are responsible for the proper use of the booked car and must adhere to local traffic laws.</li>
                </ul>
            </li>
            <li className='listMainTC'>Damages:
                <ul>
                    <li className='listContentTC'>Users are liable for any damages caused to the car during the booking period.</li>
                </ul>
            </li>
            <li className='listMainTC'>Privacy:
                <ul>
                    <li className='listContentTC'>We respect your privacy. Read our <Link to="/PrivacyPolicy">Privacy Policy</Link> for more details.</li>
                </ul>
            </li>
            <li className='listMainTC'>Security:
                <ul>
                    <li className='listContentTC'>Protect your account credentials. Any unauthorized use is your responsibility.</li>
                </ul>
            </li>
        </ol>
        <div className='lastUpdated'>
            <p>These terms and conditions are subject to change without notice. It is your responsibility to review them regularly.</p>
            <p>Last updated: January 23, 2024</p>
        </div>
        <ul>
            <li className='listMainTC'>Cancellation / Refund Policies:
                <ul>
                    <li className='listContentTC'>We do not offer any refunds on orders that are processed or any cancellation facilites on orders.</li>
                </ul>
            </li>
        </ul>
      </div>
    </div>
  )
}

export default TermsAndCondition;
